@mixin defaultPageStyle {
  height: 100vh;
  background-color: #171717;
  overflow-y: auto;
}

.landingScreenBackground {
  @include defaultPageStyle;

  .landingSideBar {
    // NOTE: Replaced with px from rem due to button div leak
    // min-width: 600px;
    align-content: center;

    .landingLeftSideRow {
      justify-content: center;
    }

    /* 
 ! Logo Design for landing Page 
*/
    .landingImage {
      padding: 48px 0px 0px 0px;

      .logoStyle {
        width: 301px;
        height: 64px;
      }
    }

    /* 
  ! Logo Design for Cmate Account Page 
*/
    .loginCreateImage {
      padding: 32px 0px 0px 0px;

      .loginCreateContactUs {
        width: 0px 0px 0px 140px;

        .contactUsUnderline {
          padding: 8px 0px 0px 0px;
          width: 12px;
          height: 0px;
          border: 1rem solid #e1e1e1;
        }
      }
    }

    /* 
  ! Landing page 
*/

    .landingTitle {
      padding: 104px 0px 0px 0px;
    }

    .landingDescription {
      padding: 24px 0px 0px 0px;
      max-width: 408px;
    }

    .inputTextStyle1 {
      padding: 48px 0px 0px 0px;
    }

    .inputTextStyle2 {
      padding: 24px 0px 0px 0px;
    }

    .errorMessageRowStyle {
      padding: 10px 0px 0px 0px;

      .errorMessageIconStyle {
        padding: 0px 0px 0px 0px;
      }
      .errorMessageColoumnStyle {
        padding: 0px 0px 0px 0px;
        padding: 2px 0px 0px 0px;
      }
    }

    .radioButtonStyle1 {
      padding: 56px 0px 0px 0px;
    }

    .radioButtonStyle2 {
      padding: 24px 0px 0px 0px;
    }

    .contactuspadding {
      padding: 16px 0px 0px 0px;
      width: 408px;
      text-align: center;
    }

    /* 
  ! OTP Verfification page 
*/

    .inputTextStyleOtp {
      padding: 64px 0px 0px 0px;
    }

    .resendOtpStyle {
      @media (min-width: 460px) {
        width: 400px;
      }
      @media (max-width: 460px) {
        width: 240px;
      }

      max-width: 400px;
      text-align: end;
      padding: 16px 0px 0px 0px;
    }

    .otpButtonMargin {
      padding: 136px 0px 0px 0px;
    }

    .successOtpIcon {
      text-align: center;
      width: 360px;
      padding: 82.4px 0px 0px 0px;
    }

    .successOtpStyle {
      padding: 24px 0px 0px 0px;
      text-align: center;
    }

    .successOtpDescription {
      padding: 48px 0px 0px 0px;
      max-width: 408px;
    }

    .successOtpButtonMargin {
      padding: 72px 0px 0px 0px;
    }

    .failedOtpButtonMargin {
      padding: 128px 0px 0px 0px;
    }

    .successOtpButtonpadding {
      padding: 72px 0px 0px 0px;
    }

    .otpButtonpadding {
      padding: 136px 0px 0px 0px;
    }

    .otpButtonpaddingDisabled {
      padding: 300px 0px 0px 0px;
    }

    .otpCancelStyle {
      max-width: 408px;
      text-align: center;
      padding: 16px 0px 16px 0px;
    }
  }

  /* 
  ! Landing Page Main Image
*/

  .landingImageAlign {
    background-color: #232323;
    height: 100vh;
    justify-items: center;
    .landingMainImg {
      width: 100%;
      height: 80vh;
    }

    .landingPageImageMainRow {
      justify-content: center;
    }

    .landingPageImageTitle {
      margin: 45px 0px 20px 0px;
    }
  }
}

.loginButtonStyle {
  padding: 110px 0px 16px 0px;
}

.defaultLandingPageButtonStyle {
  padding: 64px 0px 0px 0px;
}

.clickableLink {
  margin: 16px 0px 16px 0px;
  max-width: 408px;
  text-align: center;
}
