@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap");
html{
  font-size: 1vw;
}
html a {
  all: unset;
  text-decoration: none;
  cursor: pointer;
}
html a:hover {
  all: unset;
  text-decoration: none;
  cursor: pointer;
}
