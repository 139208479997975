.loginInputFieldStyle {
  max-width: 408px;
  height: 56px;
  border: 2px solid #909090;
  box-sizing: border-box;

  .inputTextStyling1 {
    @media (min-width: 460px) {
      width: 400px;
    }
    @media (max-width: 460px) {
      width: 240px;
      padding-left: 25px;
    }
    height: 50px;
    display: flex;
    align-items: center;
  }

  .inputTextStyling2 {
    @media (min-width: 460px) {
      width: 400px;
    }
    @media (max-width: 460px) {
      width: 240px;
    }
    // padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .passwordIconStyle {
    @media (max-width: 460px) {
      padding-left: 70px;
    }
    display: flex;
    align-items: end;
    justify-content: end;
  }
}
/* 
  * Switch css definition.
 */
.errorLoginInputFieldStyle {
  max-width: 408px;
  height: 56px;
  border: 2px solid #ea3d4a;
  box-sizing: border-box;

  .inputTextStyling1 {
    @media (min-width: 460px) {
      width: 400px;
    }
    @media (max-width: 460px) {
      width: 240px;
    }
    height: 50px;
    display: flex;
    align-items: center;
  }

  .inputTextStyling2 {
    @media (min-width: 460px) {
      width: 400px;
    }
    @media (max-width: 460px) {
      width: 240px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .passwordIconStyle {
    @media (max-width: 460px) {
      padding-left: 70px;
    }
    display: flex;
    align-items: end;
    justify-content: end;
  }
}

.formInputField {
  // width: 428px;
  height: 40px;
  border: 2px solid #909090;
  box-sizing: border-box;
}
