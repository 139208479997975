.defaultButton {
  all: unset;
  cursor: pointer;
  width:calc(337px + 100vw - 1024px)!important;
  height: 72px;
  left: 135px;
  top: 732px;
  background: #8e85ff;
  border-radius: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;

  
}
