.transformInputFieldsContainer {
  display: flex;
  flex-direction: column;
}

.transformInputFieldsContainer.disabled {
  opacity: 0.7;
  pointer-events: none; /* Makes the component unclickable */
}

.transformConfigureParametersHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 10px 0px;
  padding-top: 8px;
}

.transformPlusButton {
  cursor: pointer;
  display: inline-block;
  transition: transform 0.2s ease;
  position: relative;
}

.transformPlusButton:hover {
  transform: scale(1.2);
}

.transformPlusButtonInner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transformThresholdSetupForm {
  border: 1px solid grey;
  padding: 15px 10px;
  margin-bottom: 15px;
}

.transformTextareaContainer {
  position: relative;
  height: 80px;
}

.transformTextarea {
  background-color: inherit;
  border: 2px solid grey;
  padding: 10px;
  margin: 0px;
  resize: none;
  outline: none;
  box-shadow: none;
  width: 100%;
  height: 80px;
  font: inherit;
  color: white;
  caret-color: white;
}

.transformTextarea::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #fafafa;
  opacity: 0.7;
}

.transformInputFieldsDescription {
  padding-top: 8px;
}

.transformFormButtons {
  height: 50px;
  display: flex;
  justify-content: flex-end;
}

.transformConfigListItem {
  border: 2px solid grey;
  height: 40px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
}

.transformConfigListItemContent {
  display: flex;
  align-items: center;
}

.transformConfigListItemText {
  font-size: 16px;
  color: white;
  margin: 0;
}

.transformConfigListItemButtons {
  display: flex;
}

.transformSettingsButton {
  height: 24px;
  width: 24px;
  margin-right: 20px;
  cursor: pointer;
}

.transformRemoveButton {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.transformSettingsButton:hover,
.transformRemoveButton:hover {
  cursor: pointer;
}

.transformSettingsIcon,
.transformCloseIcon {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
