/* 
* Main Column height when ViewDropDown will be False.
*/
.progressBarMainColumnPrimary {
	height: 72px;
	background-color: #313131;
}

/* 
* Main Column height when ViewDropDown will be True.
*/
.progressBarMainColumnSecondary {
	height: 250px;
	background-color: #313131;
}

/* 
* Progress Bar
*/
.progressBarFirstColumn {
	height: 32px;
	margin: 10px 0px 0px 0px;

	.progressBarDropDownIcon {
		margin: 3px 0px 0px 0px;
	}
	.progressBarPercentage {
		text-align: end;
	}
}

/* 
* All Lists Styling - without ScrollBar
*/
.progressBarContainerStyle {
	.progressBarList {
		margin: 0px 0px 16px 0px;
		height: 24px;

		.progressBarListTitle {
			text-align: start;
			padding: 0px 0px 0px 0px;
		}

		.progressBarListIcon {
			text-align: end;
		}
	}
}

/* 
* All Lists Styling - with ScrollBar
*/
.progressBarFlexScroll {
	height: 150px;
	overflow-y: auto;

	.progressBarList {
		margin: 0px 0px 16px 0px;
		height: 24px;

		.progressBarListTitle {
			text-align: start;
			padding: 0px 0px 0px 0px;
		}

		.progressBarListIcon {
			text-align: end;
		}
	}
}

/* 
* ScrollBar Width Styling
*/
.progressBarFlexScroll::-webkit-scrollbar {
	width: 6px;
}

/* 
* ScrollBar Track Styling
*/
.progressBarFlexScroll::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(142, 133, 255, 0.4);
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

/* 
* ScrollBar Handle Styling
*/
.progressBarFlexScroll::-webkit-scrollbar-thumb {
	// -webkit-border-radius: 5px;
	border-radius: 5px;
	background: #8e85ff;
	box-shadow: inset 0 0 6px rgba(142, 133, 255, 0.4);
}
